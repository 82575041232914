<template>
  <v-container>
    <BaseTitlePage class="mb-0" />

    <BaseSearchPage class="mt-0" title="Cadastrar novo titular e/ou dependente">
      <template v-slot:form>
        <v-form
          ref="form"
          lazy-validation
          v-model="valid"
          @submit.prevent="init"
        >
          <v-row dense>
            <v-col
              cols="12"
              :xl="tabVisible ? 6 : 4"
              :lg="tabVisible ? 6 : 4"
              :md="tabVisible ? 6 : 4"
              :sm="6"
            >
              <v-text-field
                id="customer"
                name="customer"
                label="Cliente"
                hide-details
                dense
                outlined
                readonly
                v-model="customer"
              >
                <template v-slot:append>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-icon v-on="on" color="primary">
                        fa-solid fa-circle-info
                      </v-icon>
                    </template>
                    <span>
                      Para mudar o cliente, volte para o menu movimentações
                    </span>
                  </v-tooltip>
                </template>
              </v-text-field>
            </v-col>
            <v-col cols="12" xl="6" lg="6" md="6" sm="6">
              <BaseContractsMovements
                id="contracts"
                name="contracts"
                label="Contratos"
                hide-details
                required
                :customerId="$route.params.customerId"
                :rules="[requiredLength]"
                :disabled="tabVisible"
                v-model="info.contracts"
              />
            </v-col>
            <v-col cols="12" xl="2" lg="2" md="2" sm="6" v-if="!tabVisible">
              <BaseButton
                id="btn-init"
                name="btn-init"
                type="submit"
                height="40"
                color="primary"
                title="Iniciar"
                :disabled="!valid"
              />
            </v-col>
          </v-row>
        </v-form>
      </template>
    </BaseSearchPage>

    <v-row v-if="tabVisible">
      <v-col cols="12">
        <v-tabs
          show-arrows
          class="b-tab-vertical"
          :vertical="checkMobile"
          v-model="tab"
        >
          <template v-for="(header, index) in headers">
            <v-tab
              class="b-tab-vertical--tab"
              :disabled="header.disabled"
              :href="`#${header.href}`"
              :key="index"
              v-if="header.visible"
            >
              <v-icon left :size="20" v-if="checkMobile">
                {{ header.icon }}
              </v-icon>
              {{ `${header.order} - ${header.title}` }}
            </v-tab>
          </template>

          <v-tabs-items v-model="tab" class="b-tab-vertical--items">
            <template v-for="(content, index) in headers">
              <v-tab-item
                v-if="content.visible"
                :value="content.href"
                :key="index"
              >
                <component
                  v-if="content.href === tab"
                  :is="content.component"
                  :editMode="editMode"
                  :contracts="info.contracts"
                  :customerId="$route.params.customerId"
                  :employeeId="$route.params.employeeId"
                  @updataInfo="search"
                  @back="back"
                />
              </v-tab-item>
            </template>
          </v-tabs-items>
        </v-tabs>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { rulesMixin } from '@/mixins/rules';
import { MovementService } from '@/services/api/movements';

export default {
  mixins: [rulesMixin],

  components: {
    PageEmployee: () => import('@/components/pages/movements/page-employee'),
    PageDependents: () =>
      import('@/components/pages/movements/page-dependents'),
    PageDocuments: () => import('@/components/pages/movements/page-documents'),
    PageHistory: () => import('@/components/pages/movements/page-history'),
    PageBilling: () => import('@/components/pages/movements/page-billing'),
    PagePlans: () => import('@/components/pages/movements/page-plans')
  },

  data: () => ({
    tabVisible: false,
    valid: true,
    tab: null,
    info: {
      contracts: []
    }
  }),

  created() {
    if (this.editMode) {
      this.search();
    }
  },

  computed: {
    editMode() {
      const { employeeId } = this.$route.params;

      return employeeId ? true : false;
    },

    customer() {
      return this.$store.getters['movements/customerName'];
    },

    headers() {
      return this.$store.getters['movements/getTabs'];
    },

    checkMobile() {
      return this.$vuetify.breakpoint.smAndDown ? false : true;
    }
  },

  methods: {
    async search() {
      try {
        const movementService = new MovementService();
        const { status, data } = await movementService.getMovementsInfo(
          this.$route.params
        );

        if (status === 200) {
          this.info = data;

          if (data.contracts.length > 0) {
            this.tabVisible = true;
          }
        }
      } catch (error) {
        throw new Error(error);
      }
    },

    init() {
      if (!this.$refs.form.validate(true)) return;

      this.tabVisible = true;
    },

    back() {
      const { customerId, employeeId, returnUrl } = this.$route.params;

      if (returnUrl) {
        this.$router.push({
          name: 'movimentacoes-edit',
          params: { customerId, employeeId, employeeOrDependentId: employeeId }
        });
      } else {
        this.$router.push({
          name: 'movimentacoes-list',
          params: { customerId }
        });
      }
    }
  }
};
</script>
